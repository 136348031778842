.don-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(rgba(9, 5, 54, 0.3), rgba(5, 4, 46, 0.7)),
    url('../../img/donate-bg.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  gap: 20px;
}

.don-body > h2 {
  font-size: 4rem;
}


.don-btn {
  width: 160px;
  text-align: center;
  border-radius: 5px;
  font-size: 1.3rem;
  padding: 9px 13px;
  background-color: #2b9dce;
  color: #fff;
}

/* For the tablet view */
/* @media screen and (max-width: 992px) {
  .don-body > h2 {
    font-size: 3rem;
  }
  .don-body > p {
    font-size: 1.7rem;
    width: 700px;
    text-align: center;
  }
} */

/* For the mobile View */
/* @media screen and (max-width: 576px) {
  .don-body > h2 {
    font-size: 2.5rem;
  }
  .don-body > p {
    font-size: 1.5rem;
    width: 360px;
    text-align: center;
  }
} */
